import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import formEditorActions from "@actions/formEditorActions";

const useFormActions = () => {
    const dispatch = useDispatch()
    const { moduleId } = useParams()
    const { formFields, tableFields, formDetails, moduleCategories, loading, moduleLookup, columnLookup, mlProviders, mlServices, aiServices } = useSelector(state => state.formEditorReducer);
    const deleteForm = (moduleId, onSuccess) => dispatch(formEditorActions.deleteForm({ moduleId, onSuccess }))
    const getFormDetails = async (id, reload) => {
        const formId = id || moduleId
        const formDetail = formDetails[formId]
        if (!formDetail || reload) {
            return dispatch(formEditorActions.getFormDetail(formId))
        } else {
            return formDetail
        }
    }
    const getModuleCategories = () => {
        dispatch(formEditorActions.getModuleCategories());
    }
    const getMLProviders = () =>{
        dispatch(formEditorActions.getMLProviders());
    }
    const getMLServices = ({providerId}) =>{
        dispatch(formEditorActions.getMLServices({providerId}));
    }
    const getAIServices = ({mlServiceId}) =>{
        dispatch(formEditorActions.getAIServices({mlServiceId}));
    }
    const getModuleLookup = (id) => {
        if (moduleLookup.length <= 0) {
            dispatch(formEditorActions.getModuleLookup(id));
        }
    }
    const getColumnLookup = (id) => {
        if (columnLookup.length <= 0) {
            dispatch(formEditorActions.getColumnLookup(id));
        }
    }
    const loadListApplications = async () => {
        dispatch(formEditorActions.getListApplications())
    }

    return {
        deleteForm,
        getFormDetails,
        getModuleCategories,
        formDetails,
        formFields,
        tableFields,
        moduleCategories,
        loading,
        moduleLookup,
        columnLookup,
        getModuleLookup,
        getColumnLookup,
        mlProviders,
        getMLProviders,
        mlServices,
        getMLServices,
        aiServices,
        getAIServices,
        loadListApplications
    }
}

export default useFormActions