/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Switch, Redirect } from "react-router-dom";// , useHistory

import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { ContentRoute } from "../../../../_metronic/layout"
import Login from "./Login";
import ForgotPassword from "./ForgotPassword";
import MfaPage from "./MfaPage";
import SsoPage from "./Sso";
import { shallowEqual, useSelector } from "react-redux";
import "../../../../_metronic/_assets/sass/pages/login/classic/login-1.scss";
import AuthLayout from "../../../layouts/AuthLayout";

export function AuthPage(props) {
	// const history = useHistory();
	const { requiresTwoFactor } = useSelector(
		({ auth }) => ({
			requiresTwoFactor: auth.requiresTwoFactor,
		}),
		shallowEqual
	);

	const { redirectUrl } = props;

	return (
		<AuthLayout>
			<div className="d-flex flex-column flex-root">
				<div
					className="login login-4 login-signin-on d-flex flex-row-fluid"
					id="kt_login"
				>
					<div className="d-flex flex-center flex-row-fluid bgi-size-cover bgi-position-top bgi-no-repeat">
						<div className={`${!requiresTwoFactor && 'login-form text-center p-7'} position-relative overflow-hidden`}>
							{requiresTwoFactor ?
								<MfaPage />
								:
								<Switch>
									<ContentRoute path="/authentication/login" component={Login} />
									<ContentRoute
										path="/authentication/forgot-password"
										component={ForgotPassword}
									/>
									<ContentRoute path="/authentication/mfa" component={MfaPage} />
									<ContentRoute path="/authentication/sso" component={SsoPage} />
									<Redirect from="/authentication" exact={true} to="/authentication/login" />
									<Redirect to={redirectUrl} />
								</Switch>
							}
						</div>
					</div>
				</div>
			</div>
		</AuthLayout>
	);
}
