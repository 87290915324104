/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, NavLink } from "react-router-dom";
import { useDebounceEffect } from "ahooks";
import { Input, Badge } from "antd";
import { checkIsActive } from "@metronic/_helpers"; // toAbsoluteUrl, 
import formEditorActions from "@actions/formEditorActions";
import { mapSidebarMenuTrees } from "@utils/form-editor/normalize-response";
import CreateModule from "./CreateModule";
import style from "../style.module.scss";
import * as appMenuActions from "@modules/ApplicationMenu/_redux/applicationMenuActions";

export function AsideMenuList({ layoutProps }) {
    const dispatch = useDispatch();
    const location = useLocation();
    const { moduleFavourite, tasksCount, menuTrees } = useSelector(
        (state) => ({
            ...state.formEditorReducer,
            menuTrees: state.applicationMenu.menus
        })
    );
    const { isFullUser, isConsultant } = useSelector(({ auth }) => auth.config || {});
    const favouriteList = moduleFavourite || [];
    const [dataSidebar, setDataSidebar] = useState([]);
    const [loadingSide, setLoadingSide] = useState(false);
    const [createFromScratch, setCreateFromScratch] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const getMenuItemActive = (url, hasSubmenu = false) => {
        return checkIsActive(location, url) ? ` ${!hasSubmenu && "menu-item-active"} menu-item-open ` : "";
    };

    const resetLastHistory = () => {
        dispatch(formEditorActions.setLastHistory({
            moduleId: '',
            tab: 'Tasks',
            page: 1
        }))
    }


    const loadFavouriteList = async () => {
        await dispatch(formEditorActions.getFavouriteModule());
    };

    const loadTasksCount = async () => {
        await dispatch(formEditorActions.getTasksCount())
    };

    const loadMenuTree = async () => {
        await dispatch(appMenuActions.fetchMenus())
    }

    useDebounceEffect(() => {
        if (menuTrees?.[0]?.id) {
            setDataSidebar(mapSidebarMenuTrees(menuTrees, searchTerm))
        }
        // eslint-disable-next-line
    }, [menuTrees, searchTerm], {
        wait: 500,
        leading: false,
    })

    useEffect(() => {
        let listLoaded = false;
        if (!listLoaded) {
            loadMenuTree();
            loadFavouriteList();
            setLoadingSide(false);
            loadTasksCount();
        }
        return () => {
            listLoaded = true;
        };
        // eslint-disable-next-line
    }, []);

    const STATIC_MENUS = [
        { url: "/tasks", label: "Home", icon: "fa-home", isFullUserMenu: false, action: resetLastHistory },
        { url: "/dashboard", label: "Dashboards & Reports", icon: "fa-tachometer", isFullUserMenu: true },
        { url: "/business-intelligence", label: "Business Intelligence", icon: "fa-chart-line", isFullUserMenu: true },
        { url: "/inquiry", label: "Quick Report", icon: "fa-search-plus", isFullUserMenu: true }, // Advanced Search
        { url: "/applications", label: "Apps", icon: "fa-columns", isFullUserMenu: false }
    ];
    const RenderMenuIcon = ({ icon, iconPrefix = '', menuClass = '' }) => {
        return icon?.match('fa-')
            ? <span className={`nav-sidebar ${menuClass}`} style={{ marginLeft: '-10px' }}><i className={`text-white d-inline-block far ${icon}`} /></span>
            : <span className={`nav-sidebar ${iconPrefix}${icon} ${menuClass}`} style={{ marginLeft: '-10px' }} />
    }
    const RenderMenuGroup = ({ item, idx, subMenu }) => {
        return <li
            key={"menu_item_rel" + item?.id + idx}
            data-menu-toggle={layoutProps.menuDesktopToggle}
            aria-haspopup="true"
            className={"menu-item menu-item-submenu menu-item-rel" + getMenuItemActive(item?.id, true)}
            title={item?.description}
        >
            <div
                key={"menu_item_rel" + item?.id + idx}
                className={`align-items-center menu-link menu-toggle`}
                to={item?.id}
            >
                {subMenu && <span className={style["line"]} />}
                <div className={`menu-text`} >
                    <RenderMenuIcon icon={`${item?.id === "#" ? 'fa-list' : item?.icon}`} />
                    <span>{item?.name}</span>
                </div>
                {!!item?.children?.length && <div className="hide-asidemin plus-minus-menu text-white" />}
            </div>
            <div className={"menu-submenu " + style["bg-sidenav"]}>
                <ul className="menu-subnav">
                    {(item?.children || []).map((val, cIdx) => {
                        return val?.type !== "Module" ?
                            RenderMenuGroup({ item: val, idx: cIdx, subMenu: true })
                            :
                            RenderModuleItem({ val, idx: cIdx })
                    })}
                </ul>
            </div>
        </li>
    }
    const RenderModuleItem = ({ val, idx }) => {
        const detailPage = `/module/${val?.id}?from=sidebar`;
        const isActive = checkIsActive(location, detailPage);
        return (
            <li
                key={"sub_nav_" + val?.id + idx}
                className={`menu-item ${style["nav-container"]}` + getMenuItemActive(detailPage)}
            >
                <NavLink
                    key={"menu_item_link" + val?.id + idx}
                    to={detailPage}
                    className="menu-link"
                    onClick={resetLastHistory}
                >
                    <span className={style["line"]} />
                    {isActive ? <span className={style["circle-active"]} /> : <span className={style["circle"]} />}
                    <span className={"menu-text " + style["margin-text"]}>
                        {val?.text}
                    </span>
                </NavLink>
            </li>
        );
    }

    return (
        <>
            <ul className={"menu-nav " + layoutProps.ulClasses}>
                <li className={"menu-item findMenus " + style["list-search"]}>
                    <Input
                        type="text"
                        className={style["menu-search"]}
                        placeholder="Type here to Search"
                        value={searchTerm}
                        suffix={<b className="fal fa-search" />}
                        onChange={(e) => setSearchTerm(e.target.value)}
                    />
                </li>

                {STATIC_MENUS.map(v => // "menu-item " + getMenuItemActive(v.url, false)
                    (((isFullUser || isConsultant) && v.isFullUserMenu) || !v.isFullUserMenu) && (
                        <li key={v.url} className="menu-item">
                            {v.url.includes('dashboard') ? <a href={v.url} className={`menu-link d-flex align-items-center ${checkIsActive(location, v.url) ? "active" : ""}`}>
                                <RenderMenuIcon icon={`${v.icon}`} iconPrefix="navicon-" />
                                <span className="menu-text">{v.label}</span>
                            </a> : <NavLink to={v.url} onClick={v.action} className="menu-link d-flex align-items-center">
                                <RenderMenuIcon icon={`${v.icon}`} iconPrefix="navicon-" />
                                <span className="menu-text">{v.label}</span>
                                {v.label === "Home" && !!tasksCount &&
                                    <Badge count={tasksCount} overflowCount={9999} style={{ backgroundColor: '#1BC5BD' }} />
                                }
                            </NavLink>}
                        </li>
                    )
                )}
                {favouriteList.length > 0 ? (
                    <>
                        <li
                            key="menu_item_rel navigation-favourite"
                            data-menu-toggle={layoutProps.menuDesktopToggle}
                            aria-haspopup="true"
                            className={"menu-item menu-item-submenu menu-item-rel" + getMenuItemActive("navigation-favourite", true)}
                        >
                            <NavLink
                                className="align-items-center menu-link menu-toggle"
                                to="navigation-favourite"
                            >
                                <RenderMenuIcon icon={'fa-thumbtack'} />
                                <span className="menu-text">Favourites</span>
                                <span className="hide-asidemin plus-minus-menu text-white" />
                                {/* <i className="menu-arrow" /> */}
                            </NavLink>

                            {favouriteList.map((module) => {
                                const detailPage = `/module/${module.id}?from=sidebar`;
                                const categoryIcon = module?.category_icon ? module?.category_icon : 'navicon-Favourites';
                                return (
                                    <div key={module.id} className={"menu-submenu " + style["bg-sidenav"]}>
                                        <ul className="menu-subnav">
                                            <li
                                                key={"sub_nav_" + module.id}
                                                className={"menu-item" + getMenuItemActive(detailPage)}
                                            >
                                                <NavLink to={detailPage} onClick={resetLastHistory} className="menu-link">
                                                    <RenderMenuIcon icon={categoryIcon} menuClass={style["favourite-icon"]} />
                                                    <span className="menu-text">{module.title || module.display_name}</span>
                                                </NavLink>
                                            </li>
                                        </ul>
                                    </div>
                                );
                            })}
                        </li>
                    </>
                ) : null}
                {!loadingSide ? (
                    <>
                        {dataSidebar.length > 0 ? dataSidebar.map((item, idx) => {
                            return item?.id ?
                                RenderMenuGroup({ item, idx })
                                : null;
                        }) : null}
                    </>
                ) : null}
            </ul>
            {
                (isFullUser || isConsultant)
                    ? (
                        <ul className="menu-nav">
                            <li className="menu-item text-center">
                                <div
                                    onClick={() => setCreateFromScratch(true)}
                                    className={"btn btn-link text-decoration-none fa-lg fal fa-plus-circle " + style["nav-green--txt"]}
                                >
                                    <span className="ml-2 hide-asidemin">Create App</span>
                                </div>
                            </li>
                        </ul>
                    )
                    : null
            }

            <CreateModule
                show={createFromScratch}
                onClose={() => setCreateFromScratch(false)}
            />
        </>
    );
}
