export const strToSnakeCase = (str = '') => {
    return str.replace(/\s/g, '_').toLowerCase();
}

export const normalizeJsonString = str => {
    return str.replace(/\\+(?=")/g, '\\')
}

export const stripHTML = (string) => {
    let el = new DOMParser().parseFromString(string, 'text/html');
    return el?.body?.textContent || "";
}

export default {
    strToSnakeCase, 
    normalizeJsonString,
    stripHTML,
}

